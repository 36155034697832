import React, { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { format, parseISO, subMonths } from 'date-fns';
import fileDownload from 'js-file-download';

import ReportNavbarFilter from '../../../../components/filters/report-navbar-filter';
import Content from '../../../../components/layouts/content';
import { Slot } from '../../../../contexts/slot';
import resources from '../../../../resources';

interface IReport {
  category: string;
  description: string;
  fromDate?: Date;
  toDate?: Date;
  loading: boolean;
  onDownload(): void;
}
const Report: React.FC<IReport> = ({
  category,
  description,
  fromDate,
  toDate,
  loading,
  onDownload,
}) => (
  <Paper sx={{ p: 3 }}>
    <Grid container>
      <Grid
        item
        md={3}
        xs={12}
      >
        <Typography
          sx={{ display: 'block' }}
          variant="caption"
        >
          Categoria
        </Typography>
        {category}
      </Grid>
      <Grid
        item
        md
        xs={12}
      >
        <Typography
          sx={{ display: 'block' }}
          variant="caption"
        >
          Descrição
        </Typography>
        {description}
      </Grid>
      {!!fromDate && !!toDate && (
        <Grid
          item
          md={3}
          xs={12}
        >
          <Typography
            sx={{ display: 'block' }}
            variant="caption"
          >
            Período
          </Typography>
          {`${format(fromDate, 'dd/MM/yyyy')} à ${format(
            toDate,
            'dd/MM/yyyy'
          )}`}
        </Grid>
      )}
      <Grid
        alignSelf="center"
        item
        md="auto"
        xs={12}
      >
        <Button
          color="primary"
          disabled={loading}
          fullWidth
          onClick={onDownload}
          startIcon={loading && <CircularProgress size={10} />}
        >
          <strong>Download</strong>
        </Button>
      </Grid>
    </Grid>
  </Paper>
);

const DEFAULT_LOADINGS = {
  userAcceptReports: false,
  userActiveReports: false,
  propertyActiveReports: false,
  termsOfUseReports: false,
  resultsOutsideTheQualityStandardReports: false,
};

const ReportList: React.FC = () => {
  const [loadings, setLoadings] = useState(DEFAULT_LOADINGS);

  const [searchParams] = useSearchParams();

  const setLoading = useCallback(
    (key: keyof typeof DEFAULT_LOADINGS, state: boolean) => {
      setLoadings((old) => ({ ...old, [key]: state }));
    },
    []
  );

  const getParams = (query: {
    fromDate: Date | null;
    toDate: Date | null;
    centers: string[] | null;
  }) => ({
    fromDate: query.fromDate
      ? format(new Date(query.fromDate), 'yyyy-MM-dd')
      : format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
    toDate: query.toDate
      ? format(new Date(query.toDate), 'yyyy-MM-dd')
      : format(new Date(), 'yyyy-MM-dd'),
    codCenters: query.centers ?? [],
  });

  const getUserActiveReports = useCallback(
    async (query: {
      fromDate: Date | null;
      toDate: Date | null;
      centers: string[] | null;
    }) => {
      const params = getParams(query);

      setLoading('userActiveReports', true);
      const { status, payload } = await resources
        .use('user')
        .userActiveReportDownload(params);
      setLoading('userActiveReports', false);
      let filename = 'all';
      if (Object.entries(params).length > 0) {
        filename = Object.entries(params)
          .map(([, value]) => value)
          .join('-')
          .replace(/\s/, '');
      }

      if (status === 'OK') {
        fileDownload(payload, `user-active-report-${filename}.xlsx`);
      }
    },
    [setLoading]
  );

  const getUserAcceptReports = useCallback(
    async (query: {
      fromDate: Date | null;
      toDate: Date | null;
      centers: string[] | null;
    }) => {
      const params = getParams(query);

      setLoading('userAcceptReports', true);
      const { status, payload } = await resources
        .use('user')
        .userAcceptReportDownload(params);
      setLoading('userAcceptReports', false);
      let filename = 'all';
      if (Object.entries(params).length > 0) {
        filename = Object.entries(params)
          .map(([, value]) => value)
          .join('-')
          .replace(/\s/, '');
      }

      if (status === 'OK') {
        fileDownload(payload, `user-accept-report-${filename}.xlsx`);
      }
    },
    [setLoading]
  );

  const getProperyActiveReports = useCallback(
    async (query: {
      fromDate: Date | null;
      toDate: Date | null;
      centers: string[] | null;
    }) => {
      const params = getParams(query);

      setLoading('propertyActiveReports', true);
      const { status, payload } = await resources
        .use('property')
        .propertiesActiveReportDownload(params);
      setLoading('propertyActiveReports', false);
      let filename = 'all';
      if (Object.entries(params).length > 0) {
        filename = Object.entries(params)
          .map(([, value]) => value)
          .join('-')
          .replace(/\s/, '');
      }

      if (status === 'OK') {
        fileDownload(payload, `properties-active-report-${filename}.xlsx`);
      }
    },
    [setLoading]
  );

  const getResultsOutsideTheQualityStandardReports = useCallback(
    async (query: {
      fromDate: Date | null;
      toDate: Date | null;
      centers: string[] | null;
    }) => {
      const params = getParams(query);

      setLoading('resultsOutsideTheQualityStandardReports', true);
      const { status, payload } = await resources
        .use('quality')
        .resultsOutsideTheQualityStandardReportsDownload({ ...params });
      setLoading('resultsOutsideTheQualityStandardReports', false);
      let filename = 'all';
      if (Object.entries(params).length > 0) {
        filename = Object.entries(params)
          .map(([, value]) => value)
          .join('-')
          .replace(/\s/, '');
      }

      if (status === 'OK') {
        fileDownload(payload, `quality-result-outside-${filename}.xlsx`);
      }
    },
    [setLoading]
  );
  const getTermsOfUseReports = useCallback(
    async (query: {
      fromDate: Date | null;
      toDate: Date | null;
      centers: string[] | null;
    }) => {
      const params = getParams(query);

      setLoading('termsOfUseReports', true);
      const { status, payload } = await resources
        .use('util')
        .termsOfUseReportDownload({ ...params });
      setLoading('termsOfUseReports', false);
      let filename = 'all';
      if (Object.entries(params).length > 0) {
        filename = Object.entries(params)
          .map(([, value]) => value)
          .join('-')
          .replace(/\s/, '');
      }

      if (status === 'OK') {
        fileDownload(payload, `terms-of-use-report-${filename}.xlsx`);
      }
    },
    [setLoading]
  );

  const query = {
    toDate: searchParams.has('toDate')
      ? parseISO(searchParams.get('toDate')!)
      : new Date(),
    fromDate: searchParams.has('fromDate')
      ? parseISO(searchParams.get('fromDate')!)
      : subMonths(new Date(), 1),
    centers: searchParams.getAll('centers') ?? [],
  };

  return (
    <Content title="Relatórios">
      <Slot name="navbar">
        <ReportNavbarFilter />
      </Slot>
      <Stack
        direction="column"
        spacing={1}
      >
        <Report
          category="Usuários aceitos"
          description="Relatório de usuário aceitos"
          fromDate={query.fromDate}
          loading={loadings.userAcceptReports}
          onDownload={() => getUserAcceptReports(query)}
          toDate={query.toDate}
        />
        <Report
          category="Usuários ativos"
          description="Relatório de usuário ativos"
          fromDate={query.fromDate}
          loading={loadings.userActiveReports}
          onDownload={() => getUserActiveReports(query)}
          toDate={query.toDate}
        />
        <Report
          category="Propriedades ativas"
          description="Relatório de propriedades ativas"
          fromDate={query.fromDate}
          loading={loadings.propertyActiveReports}
          onDownload={() => getProperyActiveReports(query)}
          toDate={query.toDate}
        />
        <Report
          category="Termos de Uso"
          description="Relatório de aceites de uso"
          fromDate={query.fromDate}
          loading={loadings.termsOfUseReports}
          onDownload={() => getTermsOfUseReports(query)}
          toDate={query.toDate}
        />
        <Report
          category="Qualidade"
          description="Resultados fora do padrão"
          fromDate={query.fromDate}
          loading={loadings.resultsOutsideTheQualityStandardReports}
          onDownload={() => getResultsOutsideTheQualityStandardReports(query)}
          toDate={query.toDate}
        />
      </Stack>
    </Content>
  );
};

export default ReportList;
